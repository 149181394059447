<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col col="12" sm="12" md="12">
          <h2>Spouse Info.</h2>
          <v-divider></v-divider>
        </v-col>

         
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sSurname"
            label="Last Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sFirstname"
            label="First Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sMiddlename"
            label="Middle Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sExtentionname"
            label="Ext Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sOccupation"
            label="Occupation"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sBusinessname"
            label="Employer/Business"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sBusinessaddress"
            label="Business Address"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="sTel"
            label="Tel. no."
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col col="12" sm="12" md="12">
          <h2>Father's Name</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="FLiDe"
            label="Living or Deceased?"
            dense
            outlined
            :items="LiDeOpt"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="fSurname"
            :rules="[vv.init(this.fSurname), vv.required(), vv.maxlen(80)]"
            label="Last Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="fFirstname"
            :rules="[vv.init(this.fFirstname), vv.required(), vv.maxlen(80)]"
            label="First Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="fMiddlename"
            label="Middle Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="fExtensionname"
            label="Ext Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col col="12" sm="12" md="12">
          <h2>Mother's Maiden Name</h2>
          <v-divider></v-divider>
        </v-col>
         <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="MLiDe"
            label="Living or Deceased?"
            dense
            outlined
            :items="LiDeOpt"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="mMaidenname"
            :rules="[vv.init(this.mMaidenname), vv.required(), vv.maxlen(80)]"
            label="Last Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="mFirstname"
            :rules="[vv.init(this.mFirstname), vv.required(), vv.maxlen(80)]"
            label="First Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="mMiddlename"
            label="Middle Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-btn
            class="mr-4"
            :disabled="!valid"
            @click="savechanges()"
            color="success"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Your changes have been successfully saved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import vldn from "@/js/validation";
export default {
  name: "Fam",
  data: () => ({
    snackbar: false,
    timeout: 2000,
    valid: false,
    LiDeOpt: [
      { value: 0, text: "Deceased" },
      { value: 1, text: "Living" },
    ],
    sSurname: "",
    sFirstname: "",
    sMiddlename: "",
    sExtentionname: "",
    sOccupation: "",
    sBusinessname: "",
    sBusinessaddress: "",
    sTel: "",
    fSurname: "",
    fFirstname: "",
    fExtensionname: "",
    fMiddlename: "",
    mMaidenname: "",
    mFirstname: "",
    mMiddlename: "",
    FLiDe: 1,
    MLiDe: 1,
    vv: vldn.methods,
  }),
  mounted() {
    this.$api.methods.init();
    this.getFambackground();
  },
  methods: {
    getFambackground() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/famback",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.sSurname = response.data[0].sSurname;
            this.sFirstname = response.data[0].sFirstname;
            this.sMiddlename = response.data[0].sMiddlename;
            this.sExtentionname = response.data[0].sExtentionname;
            this.sOccupation = response.data[0].sOccupation;
            this.sBusinessname = response.data[0].sBusinessname;
            this.sBusinessaddress = response.data[0].sBusinessaddress;
            this.sTel = response.data[0].sTel;
            this.fSurname = response.data[0].fSurname;
            this.fFirstname = response.data[0].fFirstname;
            this.fExtensionname = response.data[0].fExtensionname;
            this.fMiddlename = response.data[0].fMiddlename;
            this.mMaidenname = response.data[0].mMaidenname;
            this.mFirstname = response.data[0].mFirstname;
            this.mMiddlename = response.data[0].mMiddlename;
            this.FLiDe = response.data[0].FLiDe;
            this.MLiDe = response.data[0].MLiDe;
            if(response.data[0].FLiDe == null){
              this.FLiDe = 1;
            }
            if(response.data[0].MLiDe == null){
              this.MLiDe = 1;
            }
            
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "pds/updatefamback",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            sSurname: this.sSurname,
            sFirstname: this.sFirstname,
            sMiddlename: this.sMiddlename,
            sExtentionname: this.sExtentionname,
            sOccupation: this.sOccupation,
            sBusinessname: this.sBusinessname,
            sBusinessaddress: this.sBusinessaddress,
            sTel: this.sTel,
            fSurname: this.fSurname,
            fFirstname: this.fFirstname,
            fExtensionname: this.fExtensionname,
            fMiddlename: this.fMiddlename,
            mMaidenname: this.mMaidenname,
            mFirstname: this.mFirstname,
            mMiddlename: this.mMiddlename,
            FLiDe: this.FLiDe,
            MLiDe: this.MLiDe,
          },
        })
          .then((response) => {
            if (response) {
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
  },
};
</script>
<style>
.container {
  max-width: 80%;
}
</style>
